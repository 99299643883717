(function () {
    window.elcomeUtils = {
        scrollTo: (element, scrollX, scrollY) => {
            let rect = element.getBoundingClientRect();
            if (rect.width || rect.height) {
                element.scrollTo(scrollX, scrollY);
            }
            else {
                const resizeObserver = new ResizeObserver((entries) => {
                    resizeObserver.disconnect();
                    element.scrollTo(scrollX, scrollY);
                });
                resizeObserver.observe(element);
            }
        },

        toTop: (element, smooth) => {
            let smoothText = smooth ? "smooth" : "auto"
            element.scroll({ top: 0, behavior: smoothText });
        },

        notifyAfterPoint: (container, dotNetObject, methodName, notifyPoint) => {
            if (notifyPoint == -1) notifyPoint = container.offsetHeight;
            let passed = false;

            container.addEventListener("scroll", () => {
                if (container.scrollTop > notifyPoint && passed == false)
                    passed = true;
                else if (container.scrollTop <= notifyPoint && passed == true)
                    passed = false;
                else 
                    return

                dotNetObject.invokeMethodAsync(methodName, passed);
            })
        },

        getMediaValue: (name, unit, min, max, maxSteps) => {
            while (1) {
                if (min == max || maxSteps-- < 0) {
                    return min;
                }
                let mid = (min + max) / 2;
                let mql = window.matchMedia(`(min-${name}: ${mid}${unit})`);
                if (mql.matches) {
                    min = mid;
                } else {
                    max = mid;
                }
            }
        },

        getTimeZone: () => {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        },

        // Used by "Search" button within DxComboBox
        // Find the closest input box, and send an Enter keydown event
        pressEnterInInput: (el) => {
            while (el) {
                let input = el.querySelector("input[type='text']");
                if (input) {
                    input.dispatchEvent(new KeyboardEvent("keydown", { "bubbles": true, "code": "Enter", "key": "Enter", "keyCode": 13, "which": 13 }));
                    break;
                }
                el = el.parentElement;
            }
        },

        focusId: (id, preventScroll) => {
            const el = document.getElementById(id);
            if (el) {
                el.focus({ preventScroll: preventScroll });
            }
        },

        blurId: (id, preventScroll) => {
            const el = document.getElementById(id);
            if (el) {
                el.blur();
            }
        },

        setInputValue: (id, value) => {
            const el = document.getElementById(id);
            el.value = value;
            el.dispatchEvent(new InputEvent("input"));
        }
    }
})();

(function () {

    window.elcomeHtmlEditor = {
        create: (htmlEditor, el, options) => {

            let mentionValues = {
                //"@": [
                //    { id: 1, value: "Item 1" },
                //    { id: 2, value: "Item 2" }
                //]
            };

            if (options.modules && options.modules.mention) {
                options.modules.mention.source = (searchTerm, renderList, mentionChar) => {
                    let values = mentionValues[mentionChar];

                    if (searchTerm.length === 0 || !values) {
                        renderList(values, searchTerm);
                    } else {
                        const matches = [];
                        for (let i = 0; i < values.length; i++)
                            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
                                matches.push(values[i]);
                        renderList(matches, searchTerm);
                    }
                };
            }

            // Switch to using css styles (rather than css classes) for the following
            ['align', 'direction', 'font', 'size'].forEach(c => Quill.register(Quill.import('attributors/style/' + c), true));

            const quill = new Quill(el, options);

            const getMentions = () => {
                let mentions = [];
                let contents = quill.getContents();
                if (contents && contents.ops) {
                    contents.ops.forEach(c => {
                        if (c.insert && c.insert.mention) {
                            mentions.push(c.insert.mention.id);
                        }
                    });
                }
                return mentions;
            };

            // Don't raise change event on every single key press
            // -> wait until we lose focus

            let pendingChanges = false;
            let hasFocus = false;

            const raiseHtmlChanged = () => {
                //console.log("CHANGED");
                pendingChanges = false;
                //console.log("Changed:", JSON.stringify(quill.getContents()));
                htmlEditor.invokeMethodAsync('OnHtmlChanged', quill.root.innerHTML.trim(), getMentions());
            };

            const textChange = () => {
                //console.log("textChange", arguments);

                // Bug: Deleting a mention in a specific way can result in the mention text being deleted, but not the html (or mention)
                // Reproduction steps:
                // - Type "a @"
                // - Use arrow keys to select a mention, and press enter
                // - Left click the mention to highlight it
                // - Press backspace to delete it
                // Workaround: Disable editing of the outer span (by default it is only disabled for the inner span)
                quill.root.querySelectorAll("span.mention").forEach(c => {
                    c.setAttribute('contenteditable', false);
                });

                if (hasFocus) {
                    pendingChanges = true;
                } else {
                    raiseHtmlChanged();
                }
            };

            const focusout = () => {
                //console.log("focusout", arguments);
                if (pendingChanges) {
                    raiseHtmlChanged();
                }
                hasFocus = false;
            };

            const focusin = () => {
                //console.log("focusin", arguments);
                if (pendingChanges) {
                    raiseHtmlChanged();
                }
                hasFocus = true;
            };

            const quillContainer = quill.container;

            quill.on('text-change', textChange);
            quillContainer.addEventListener('focusout', focusout);
            quillContainer.addEventListener('focusin', focusin);

            const self = {
                setHtml: (html) => {
                    const delta = quill.clipboard.convert(html);
                    quill.setContents(delta, 'silent');
                    pendingChanges = false;
                    return getMentions();
                },
                setMentionValues: (newMentionValues) => {
                    mentionValues = newMentionValues;
                },
                getMentions: getMentions,
                //getJson = () => {
                //    return quill.getContents();
                //},
                dispose: () => {
                    quill.off('text-change', textChange);
                    quillContainer.removeEventListener('focusout', focusout);
                    quillContainer.removeEventListener('focusin', focusin);
                }
            };

            return self;
        }
    };

})();

(function () {

    window.elcomeInterop = {
        construct: (className) => {
            // window.elcomeInterop.create("DevExpress.viz.dxBarGauge", el, { endValue: 100 });
            // is equivalent to
            // new window.DevExpress.viz.dxBarGauge(el, { endValue: 100 });

            // Resolve constructor function from name string
            let constr = window;
            for (let childName of className.split('.')) {
                constr = constr[childName];
            }

            let args = Array.prototype.slice.call(arguments);
            args[0] = constr; // First arg to bind() is "this" which should be set to the constructor

            let constructorWithArgs = constr.bind.apply(constr, args);

            let result = new constructorWithArgs();

            return result;
        },

        get: (self, property) => {
            if (Array.isArray(property)) {
                let value = self;
                property.forEach(p => value = value[p]);
                return value;
            } else {
                return self[property];
            }
        }
    };

})();